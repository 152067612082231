<script>
import { onMounted, ref } from "vue";
import MusicPlaylists from "@/common/components/MusicPlaylists.vue";
import { useRoute } from "vue-router";
import { getWishlistByWishlistID } from "@/api/wishlists.api";
import {
  capitalizeEveryWordFirstLetter,
  capitalizeFirstLetter,
} from "@/utils/staticHelper";
export default {
  name: "WishlistDetail",
  methods: { capitalizeEveryWordFirstLetter, capitalizeFirstLetter },
  components: {
    MusicPlaylists,
  },
  setup() {
    const loading = ref(false);
    const wishlist = ref({});
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await getWishlistByWishlistID(route.params.wishlistID);
        wishlist.value = data;
        document.title =
          wishlist.value && wishlist.value.name
            ? "Wishlist | " +
              capitalizeEveryWordFirstLetter(wishlist.value.name)
            : "";
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });
    return { loading, wishlist };
  },
};
</script>
<template>
  <div class="d-flex page-heading">
    Wishlist :
    {{
      wishlist && wishlist.name
        ? capitalizeEveryWordFirstLetter(wishlist.name)
        : ""
    }}
  </div>
  <MusicPlaylists
    :playlist-loading="loading"
    :show-toolbar-buttons="false"
    :chartmetricID="
      wishlist && wishlist.chartmetricID ? wishlist.chartmetricID : 0
    "
    :playlist-data="
      wishlist &&
      wishlist.wishlistPlaylists &&
      wishlist.wishlistPlaylists.length
        ? wishlist.wishlistPlaylists
        : []
    "
  />
</template>
