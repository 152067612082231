<script>
import { onMounted, ref } from "vue";
import ModalToolbar from "@/common/components/ModalToolbar.vue";
import { capitalizeFirstLetter } from "@/utils/staticHelper";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElNotification } from "element-plus";
import {
  addPlaylistsToExistingWishlist,
  createWishlist,
  getWishlists,
} from "@/api/wishlists.api";

export default {
  name: "PlaylistWishlistModal",
  methods: { capitalizeFirstLetter },
  components: {
    ModalToolbar,
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    selectedPlaylists: {
      type: Array,
      default: () => [],
    },
    chartmetricID: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false);
    const wishlists = ref([]);
    const showCreateWishlistForm = ref(false);
    const formRef = ref(null);
    const submitButtonRef = ref(null);
    const wishlistModalCloseButton = ref(null);
    const wishlistName = ref("");
    const validationSchema = Yup.object().shape({
      wishlistName: Yup.string().required().label("Wishlist Name"),
    });
    onMounted(() => {
      fetchWishlists();
    });

    const fetchWishlists = async () => {
      try {
        const { data } = await getWishlists(props.chartmetricID);
        wishlists.value = data && data.length ? data : [];
      } catch (e) {
        console.log(e);
      }
    };

    const createNewWishlist = async ($event) => {
      $event.preventDefault();
      if (wishlistName.value) {
        try {
          submitButtonRef.value.disabled = true;
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");
          const payload = {
            name: wishlistName.value,
            wishlistPlaylists: props.selectedPlaylists,
            chartmetricID: props.chartmetricID,
          };
          await createWishlist(payload);
          ElNotification({
            title: "Success",
            message: "Wishlist added successfully",
            type: "success",
          });
          submitButtonRef.value.removeAttribute("data-kt-indicator");
          submitButtonRef.value.disabled = false;
          wishlistModalCloseButton.value.click();
          context.emit("updatePlaylists");
          await fetchWishlists();
        } catch (e) {
          console.log(e);
          ElNotification({
            title: "Oops!",
            message: e.response.data.message,
            type: "error",
          });
          submitButtonRef.value.removeAttribute("data-kt-indicator");
          submitButtonRef.value.disabled = false;
        }
      } else {
        formRef.value.setFieldError(
          "wishlistName",
          "Wishlist Name is a required field"
        );
      }
    };

    const addToExistingWishlists = async (wishlist) => {
      try {
        loading.value = true;
        let filteredPlaylists = props.selectedPlaylists;
        if (wishlist.wishlistPlaylists && wishlist.wishlistPlaylists.length) {
          filteredPlaylists = filteredPlaylists.filter((playlist) => {
            return !wishlist.wishlistPlaylists.some(
              (item) => item.id === playlist.id
            );
          });
        }
        if (filteredPlaylists.length) {
          const payload = {
            wishlistID: wishlist._id,
            wishlistPlaylists: filteredPlaylists,
          };

          await addPlaylistsToExistingWishlist(payload);
          ElNotification({
            title: "Success",
            message: "Playlists added successfully",
            type: "success",
          });
          wishlistModalCloseButton.value.click();
          context.emit("updatePlaylists");
          await fetchWishlists();
        } else {
          ElNotification({
            title: "Success",
            message: "Playlists Already Exist",
            type: "success",
          });
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: e.response.data.message,
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      wishlists,
      showCreateWishlistForm,
      formRef,
      wishlistModalCloseButton,
      submitButtonRef,
      wishlistName,
      validationSchema,
      createNewWishlist,
      addToExistingWishlists,
    };
  },
};
</script>
<template>
  <ModalToolbar id="show_playlist_wishlist_modal" max-width="mw-800px">
    <template v-slot:header>
      <div class="modal-header" id="kt_modal_create_api_key_header">
        <h2>
          {{
            showCreateWishlistForm
              ? "Create Wishlist"
              : "Add to Existing Wishlist"
          }}
        </h2>
        <div
          class="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div
        v-show="wishlists.length && !showCreateWishlistForm"
        class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15"
        v-loading="loading"
      >
        <!--begin::Heading-->
        <div class="text-center mb-13">
          <!--begin::Title-->
          <!--end::Title-->

          <!--begin::Description-->
          <div class="text-gray-400 fw-bold fs-5 mt-5">
            If you want to create a new wishlist, then
            <a
              type="button"
              @click="showCreateWishlistForm = true"
              class="link-primary fw-bolder"
              >Click Here</a
            >.
          </div>
          <!--end::Description-->
        </div>
        <!--end::Heading-->

        <!--begin::Users-->
        <div class="mb-15">
          <!--begin::List-->
          <div class="mh-375px scroll-y me-n7 pe-7">
            <template v-for="(wishlist, index) in wishlists" :key="index">
              <!--begin::User-->
              <div
                class="
                  d-flex
                  flex-stack
                  py-5
                  border-bottom border-gray-300 border-bottom-dashed
                "
              >
                <!--begin::Details-->
                <div class="d-flex align-items-center">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-35px symbol-circle">
                    <span class="symbol-label fw-bold">
                      {{ capitalizeFirstLetter(wishlist.name.charAt(0)) }}
                    </span>
                  </div>
                  <!--end::Avatar-->

                  <!--begin::Details-->
                  <div class="ms-6">
                    <!--begin::Name-->
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="Click to add tracks in wishlist"
                      placement="top-start"
                    >
                      <a
                        @click="addToExistingWishlists(wishlist)"
                        type="button"
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark text-hover-primary
                          cursor-pointer
                        "
                      >
                        {{ capitalizeFirstLetter(wishlist.name) }}
                      </a>
                    </el-tooltip>
                    <!--end::Name-->

                    <!--begin::Email-->
                    <div class="fw-bold text-gray-400">
                      {{ new Date(wishlist.createdAt).toDateString() }}
                    </div>
                    <!--end::Email-->
                  </div>
                  <!--end::Details-->
                </div>
                <!--end::Details-->

                <!--end::Stats-->
              </div>
              <!--end::User-->
            </template>
          </div>
          <!--end::List-->
        </div>
      </div>
      <!--begin::Form-->
      <Form
        ref="formRef"
        v-show="!wishlists.length || showCreateWishlistForm"
        class="form"
        :validation-schema="validationSchema"
      >
        <!--begin::Modal body-->
        <div class="modal-body py-2 px-lg-17">
          <a
            type="button"
            @click="showCreateWishlistForm = false"
            class="link-primary fw-bolder"
            >&#8592; Back</a
          >
          <!--begin::Scroll-->
          <div
            class="scroll-y me-n7 pe-7 pt-10 pb-10"
            id="kt_modal_create_api_key_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
            data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
            data-kt-scroll-offset="300px"
          >
            <!--begin::Input group-->
            <div class="mb-5 fv-row">
              <!--begin::Label-->
              <label class="required fs-5 fw-bold mb-2">Wishlist Name</label>
              <!--end::Label-->

              <!--begin::Input-->
              <Field
                type="text"
                class="form-control form-control-solid"
                placeholder="Your Wishlist Name"
                name="wishlistName"
                v-model="wishlistName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="wishlistName" />
                </div>
              </div>
              <!--end::Input-->
            </div>
          </div>
          <!--end::Scroll-->
        </div>
        <!--end::Modal body-->

        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <button
            ref="wishlistModalCloseButton"
            type="reset"
            class="btn btn-white me-3"
            data-bs-toggle="modal"
            data-bs-target="#show_playlist_wishlist_modal"
          >
            Close
          </button>
          <button
            ref="submitButtonRef"
            type="submit"
            class="btn btn-primary"
            @click="createNewWishlist"
          >
            <span class="indicator-label"> Submit </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </Form>
    </template>
  </ModalToolbar>
</template>
